import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import AnalyticsPage from 'views/AnalyticsPage';
import Workspace from 'workspace';

const Analytics = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/analytics" component={AnalyticsPage} admin />
      </Router>
    </Workspace>
  );
};

export default Analytics;
