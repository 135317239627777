import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

import { Button, Modal, Tabs } from '@xchange/uikit';

import MainLayout from 'components/MainLayout';

import useLayout from 'hooks/useLayout';
import BarChart from './BarChart';
import BarChartClosed from './BarChartClosed';

import Context, { TransactionsContextProvider } from './context';



const AnalyticsPage = () => {
  const layout = useLayout();

  return (
    <MainLayout>
      <StyledAnalyticsPage className="transactions">
      <div className="toggle-header">
            <h1 className="title">Analytics</h1>
          
          </div>
        <div className={clsx('transactions-charts')}>
            <div className="transactions-chart">
              <BarChart title="New Deals" />
            </div>
           
          </div>
          <br></br>
          <br></br>
          <div className={clsx('transactions-charts')}>
            <div className="transactions-chart">
              <BarChartClosed title="Closed Deals" />
            </div>
           
          </div>
       
      </StyledAnalyticsPage>
    </MainLayout>
  );
};

export default props => (
  <AnalyticsPage {...props} />
);

const StyledAnalyticsPage = styled.div`
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
  }

  .tabs {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;

    .tab-item {
      width: 100%;
    }
  }

  .toggle-header {
    display: inline-flex;
    &__btn {
      margin-left: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      color: ${props => props.theme.colors.red};
    }
  }

  .transactions {
    &-charts {
      height: 318px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      overflow: auto;
      transition: height 0.2s ease-out;

      &.collapsed {
        height: 0;
      }

      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        height: auto;
        grid-template-columns: 1fr;
      }
    }
    &-chart {
      padding: 8px;
      border: 1px solid ${props => props.theme.colors.graphite};
      border-radius: 6px;
    }
  }

  .empty-list {
    margin: 12px 0;
    font-size: 14px;
    color: ${props => props.theme.colors.grayDark};
  }

  .pagination {
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .transactions-filters {
      margin-top: 24px;
      .title {
        display: none !important;
      }
    }
  }
`;

const StyledRefundModal = styled(Modal)`
  .modal-content {
    max-width: 324px;
  }
  .modal-body {
    .button {
      width: 100%;
      height: 40px;

      &:last-of-type {
        margin-top: 16px;
      }
    }
  }
`;

const StyledPaymentModal = styled(Modal)`
  .header-section {
    display: none;
  }

  .form-grid {
    grid-template-columns: 1fr !important;

    .two-wide {
      grid-column: 1 !important;
    }
  }

  form {
    .form-controls {
      flex-direction: column;

      .button {
        margin-left: 0;
        margin-top: 8px;
        width: 100%;
      }
    }
  }
`;
