import React, { useState, useMemo, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import notify from 'notify';
import styled from '@emotion/styled/macro';
import { Bar } from 'react-chartjs-2';

import { colors } from 'styles/theme.json';
import useLayout from 'hooks/useLayout';
import { tryGetFirstError } from 'utils/requests';
import { Dropdown } from 'components';
import { getAnalytics } from 'api/analytics';

import { formatCurrency } from 'utils/rifmFormatters';

const dropdownOptions = [
  { value: 'current_week', text: 'Week-to-Date' },
  { value: 'last_week', text: 'Last Week' },
  { value: 'current_month', text: 'Month-to-Date' },
  { value: 'last_month', text: 'Last Month' },
];


const BarChart = ({ title }) => {
  const [graphData, setGraphData] = useState<any>([]);
  const [dateRange, setDateRange] = useState<string>('current_week');
  const layout = useLayout();
  const isMobile = layout === 'mobile';

  const [labels, data]: [string[], number[]] = useMemo(() => {
    console.log(graphData);
    return graphData.reduce((accum: [string[], number[]], current: any) => {
      accum[0].push(dayjs.unix(current.servicing_start).format('D MMM'));
      accum[1].push(current.numfiles);
      return accum;
    }, [[], []]);
  }, [graphData]);

  const dates: { startDate: number, endDate: number; } = useMemo(() => {
    let from, to = dayjs();

    switch (dateRange) {
      case 'last_week':
        from = dayjs().subtract(1, 'week').startOf('week');
        to = dayjs().startOf('week');
        break;
      case 'current_month':
        from = dayjs().startOf('month');
        break;
      case 'last_month':
        from = dayjs().subtract(1, 'month').startOf('month');
        to = dayjs().startOf('month');
        break;
      default:
        from = dayjs().startOf('week');
    }

    return { startDate: from.unix(), endDate: to.unix() };
  }, [dateRange]);

  const fetchAnalytics = useCallback(async () => {
    try {
      const analytics = await getAnalytics(dates);
      console.log(analytics);
      setGraphData(analytics);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [dates, getAnalytics]);

  useEffect(() => {
    fetchAnalytics();
  }, [dates]);

  const config = useMemo(() => ({
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            'rgba(98, 177, 32, 0.5)'
          ],
          borderWidth: 0,
          barThickness: 24,
          borderRadius: 3,
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: ({ formattedValue }) => `$${formattedValue}`,
          }
        }
      },
      scales: {
        y: isMobile ? {} : {
          ticks: {
            callback: value => value
          }
        }
      },
      indexAxis: isMobile ? 'y' : 'x',
      onHover: false,
      title: {
        display: false
      }
    }
  }), [data, labels, isMobile]);

  const HorizontalBarChart = <Bar type='h' {...config} />;

  const VerticalBarChart = <Bar {...config} />;

  return (
    <StyledBarChart className="bar-chart">
      <div className="bar-chart__header">
        <h3>{title}</h3>
        <Dropdown
          options={dropdownOptions}
          value={dateRange}
          onChange={setDateRange}
          buttonLike
          className="bar-chart__select"
          data-cy="dropdown_date_range"
        />
      </div>
      <div className={isMobile ? 'responsive-container vertical' : 'responsive-container'}>
        {graphData.length === 0 ? (
          <h3>No data available for this period.</h3>
        ) : isMobile ? (
          HorizontalBarChart
        ) : (
              VerticalBarChart
            )}
      </div>
    </StyledBarChart>
  );
};

export default React.memo(BarChart);

const StyledBarChart = styled.div`
  .bar-chart {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__select {
      .dropdown-button {
        border: none;
      }
      .dropdown-text {
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        color: ${colors.red};
      }
      .dropdown-options {
        width: max-content;
      }
      .icon-chevron {
        fill: ${colors.red};
      }
    }
  }

  .responsive-container {
    display: flex;
    position: relative;
    height: 248px;
    width: 100%;

    h3 {
      margin: auto;
      color: ${colors.grayDark};
      top: -16px;
      position: relative;
      width: 100%;
      padding: 0 70px;
      text-align: center;
    }

    &.vertical {
      height: 563px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`;
