import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';

  
export async function getAnalytics(dates: any): Promise<any> {
    const response = await fetchWithToken(`/analytics`, { method: 'POST', body: urlEncodeBody({
        start: dates.startDate,
        end: dates.endDate
      }) });
     
    const data = await response.json();
 
    return data;
}  
export async function getAnalyticsClosed(dates: any): Promise<any> {
    const response = await fetchWithToken(`/analytics/closed`, { method: 'POST', body: urlEncodeBody({
        start: dates.startDate,
        end: dates.endDate
      }) });
     
    const data = await response.json();

    return data;
}